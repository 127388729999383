.react-grid-item > .react-resizable-handle::after {
  border: none;
}

.react-grid-item .draggable-dashlet {
  cursor: grab;
}

.react-grid-item.react-draggable-dragging .draggable-dashlet {
  cursor: grabbing;
}

.react-draggable-dragging {
  border-radius: 16px;
  background-image: linear-gradient(rgba(0, 126, 243, 0.185), transparent 10%);
  opacity: 0.8;
  border: 1px dashed gray;
}
.react-grid-item > .react-resizable-handle-sw,
.react-grid-item > .react-resizable-handle-nw,
.react-grid-item > .react-resizable-handle-se,
.react-grid-item > .react-resizable-handle-ne {
  border-radius: 0 0 17px 0;
  background-image: unset;
  border: 1.5px solid #ffffff2d;
  border-top: 0;
  border-left: 0;
  margin: -2px;
  transition: border-radius 0.3ms ease;
}

.react-grid-item > .react-resizable-handle-sw:hover,
.react-grid-item > .react-resizable-handle-nw:hover,
.react-grid-item > .react-resizable-handle-se:hover,
.react-grid-item > .react-resizable-handle-ne:hover {
  border: 4px solid #ffffff80;
  border-top: 0;
  border-left: 0;
  border-radius: 0 0 17px 0;
}

.react-grid-item > .react-resizable-handle-s,
.react-grid-item > .react-resizable-handle-w,
.react-grid-item > .react-resizable-handle-e,
.react-grid-item > .react-resizable-handle-n {
  background-image: unset;
  margin: -2px;
  transform: rotate(0deg) !important;
}
.react-grid-item > .react-resizable-handle-e {
  border-right: 1.5px solid #ffffff2d;
}

.react-grid-item > .react-resizable-handle-w {
  border-left: 1.5px solid #ffffff2d;
}

.react-grid-item > .react-resizable-handle-s {
  border-bottom: 1.5px solid #ffffff2d;
}

.react-grid-item > .react-resizable-handle-n {
  border-top: 1.5px solid #ffffff2d;
}

.react-grid-item > .react-resizable-handle-e:hover {
  border-right: 4px solid #ffffff80;
}

.react-grid-item > .react-resizable-handle-w:hover {
  border-left: 4px solid #ffffff80;
}

.react-grid-item > .react-resizable-handle-s:hover {
  border-bottom: 4px solid #ffffff80;
}

.react-grid-item > .react-resizable-handle-n:hover {
  border-top: 4px solid #ffffff80;
}

.react-grid-placeholder {
  background: rgb(0, 75, 195) !important;
  border-radius: 16px;
}
