
/* Load Roboto Variable Fonts Locally */
@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: 100 900;
  font-stretch: 75% 125%;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Italic-VariableFont_wdth,wght.ttf') format('truetype');
  font-weight: 100 900;
  font-stretch: 75% 125%;
  font-style: italic;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    color: #fff;
    height: 100vh;
    background-color: #303030;
  }

  @media print {
    body {
      background-color: #fff;
    }
  }

  body::backdrop {
    background-color: #303030;
  }

  #root {
    height: 100%;
  }

  input:-webkit-autofill {
    color: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: #fff;
  }

  .textShadow {
    text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.14),
      0px 1px 8px rgba(0, 0, 0, 0.12);
  }
}
