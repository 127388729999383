.md-textarea ul {
  list-style-type: disc;
}
.md-textarea ol {
  list-style-type: decimal;
}

.wmde-markdown {
  color: #d4d4d4;
}

.w-md-editor {
  /* remove the border */
  box-shadow: none; 
}